<template>
  <div>
    <headeBar left-arrow color="#333" :title="header_title" :background="header_background"
      :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
    <div class="share" @click="showShare = true"></div>
    <img src="https://img.chaolu.com.cn/ACT/group-punching-gz-2312/1.png?v=1" alt="">
    <div class="sec1">
      <p class="row-start-center fw6">
        <span class="s1">{{ obj.number }}</span><span>节</span>
      </p>
      <img @click="go" class="button" src="https://img.chaolu.com.cn/ACT/group-punching-gz-2312/btn.png"
        alt="">
    </div>

    <div class="sec2">
      <ul class="record-ul">
        <template v-if="obj.records.length">
          <li class="record-item flex" v-for="(item, index) in obj.records" :key="index">
            <img class="head flex-none" :src="`http://img.chaolu.com.cn${item.pic}`" alt="" />
            <div>
              <p class="p1 f28 fw6 ellipsis">{{ item.name }}</p>
              <p class="p2 f24">
                {{
                  `${item.dayTime} ${item.startTime}-${item.endTime}`
                }}
              </p>
              <p class="p3 f24 fw5 row-start-center">
                <van-icon name="location" />
                {{ item.venueName }}
              </p>
            </div>
          </li>
        </template>
        <li v-else class="empty">
          <img src="https://img.chaolu.com.cn/ACT/group-punching-202305/Group%2078%402x%20%281%29.png" alt="">
          <p class="tp">暂无打卡记录</p>
        </li>
      </ul>
    </div>

    <img src="https://img.chaolu.com.cn/ACT/group-punching-gz-2312/4.png" alt="">

    <img src="https://img.chaolu.com.cn/ACT/group-punching-gz-2312/5.png" alt="">

    <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
      @share-success="showShare = false" :shareItem="['minifriend', 'postermessage', 'save']"
      :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss">
          <img src="https://img.chaolu.com.cn/ACT/group-punching-gz-2312/shareBg.png" class="canvascss_bg" />

          <div class="canvascss_info row-center-center">
            <div class="canvascss_left">
              <img :src="userDetail.headImg" />
            </div>

            <div class="canvascss_name ellipsis">
              {{ userDetail.nickName }}
            </div>

            <div class="canvascss_right">
              <img v-if="userDetail.qrCode" :src="
                'data:image/png;base64,' +
                userDetail.qrCode
              " />
            </div>
          </div>
        </div>
      </template>
    </common-share>
  </div>
</template>

<script>
import userMixin from "@/mixin/userMixin";
import headerMixin from '@/mixin/headerMixin';
import { newAppBack, initBack } from "@/lib/appMethod";
import commonShare from "@/components/commonShare";
import headeBar from '@/components/app/headBar';
import wx from "weixin-js-sdk";
export default {
  mixins: [userMixin, headerMixin],
  components: {
    headeBar,
    commonShare
  },
  data() {
    return {
      obj: {
        number: 0,
        targetNumber: "",
        records: [],
      },
      showShare: false,
      shareItem: ["minifriend"],
      shareParams: {
        title: "周二至周六上团课，送礼还送券",
        miniImage: "https://img.chaolu.com.cn/ACT/group-punching-gz-2312/mini.jpg",
        path:
          "/pages/webView/index?webUrl=" +
          window.location.origin +
          "/#/superdeer-activity/punching-202311/group-gz-2312&userId=1",
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: "url",
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },
      userDetail: {}
    };
  },
  mounted() {
    this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => {
    });
  },
  async created() {
    await this.$getAllInfo();
    initBack();
    this.initQr();
    this.getGroup();

    if (this.appTypeStr === "mini") {
      wx.miniProgram.postMessage({
        data: {
          type: "share",
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      });
      this.isShareWx = true;
      return false;
    }
  },
  methods: {
    newAppBack,
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: 'punching202307-group',
            webUrl: `${window.location.origin}/#/superdeer-activity/punching-202307/group`
          }),
        })
        .then((res) => {
          this.userDetail = res.data;
        });
    },
    getGroup() {
      this.$axios
        .post(this.baseURLApp + "/punch/activity/schedule", {
          userId: this.userId,
          token: this.token,
          activityNo: "1203",
        })
        .then((res) => {
          this.obj = res.data;
        });
    },
    go() {
      // 跳团课页
      if (this.appTypeStr === "ios") {
        window.webkit.messageHandlers.courseExclusive.postMessage({
          teacherId: this.teacherId,
        });
      } else if (this.appTypeStr === "and") {
        App.web2native(3, "");
      } else {
        wx.miniProgram.navigateTo({
          url: `/pages/courseList/courseList`,
        });
      }
    },
  }
};
</script>
<style lang="less" scoped>
div {
  box-sizing: border-box;
}

img {
  vertical-align: top;
  width: 100%;
}

.sec1 {
  width: 100%;
  height: 982px;
  background: url(https://img.chaolu.com.cn/ACT/group-punching-gz-2312/2.png);
  background-size: 100% 100%;
  position: relative;

  p {
    line-height: 1;
    display: table;
    vertical-align: baseline;
    position: absolute;
    top: 380px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 96px;
    color: #242831;

    .s1 {
      font-size: 184px;
      margin: 0 12px 0 0;
      line-height: 1;
      display: inline-block;
      transform: translateY(12px);
    }
  }

  .button {
    width: 564px;
    height: 112px;
    position: absolute;
    bottom: 172px;
    left: 50%;
    transform: translateX(-50%);
  }
}



.sec2 {
  width: 100%;
  height: 856px;
  background: url(https://img.chaolu.com.cn/ACT/group-punching-gz-2312/3.png);
  background-size: 100% 100%;
  position: relative;
}

.share {
  width: 50px;
  height: 86px;
  position: fixed;
  top: 440px;
  right: 0;
  background: url(https://img.chaolu.com.cn/ACT/group-punching-202307/Frame%203247%402x.png);
  background-size: 100% 100%;
  z-index: 99;
}

.empty {
  width: 184px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  img {
    height: 120px;
  }

  .tp {
    font-size: 24px;
    color: #333;
    margin-top: 34px;
  }
}

.bg-1 {
  width: 100%;
}

.record-ul {
  width: 520px;
  height: 576px;
  position: absolute;
  left: 114px;
  top: 228px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 24px;
  box-sizing: border-box;

  .record-item {
    padding: 34px 0;
    color: #3C454E;
    &:not(:last-child) {
      border-bottom: 2px dashed #B9DBFE;
    }

    .head {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      margin: 0 22px 0 0;
    }

    .p1 {
      max-width: 360px;
      word-break: break-all;
      color: #242831;
      font-weight: bold;
    }

    .p2 {
      margin: 12px 0;
    }

    .p3 {
      margin: 8px 0;
    }
  }
}

@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0;
  width: @bei*654;
  height: @bei*1160;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }


  .canvascss_info {
    position: absolute;
    left: 40px;
    bottom: 24px;
    z-index: 1;
  }
  .canvascss_left {
    width: @bei*100;
    height: @bei*100;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_name {
    width: 310px;
    margin: 0 24px;
    color: #242831;
    font-weight: bold;
    font-size: @bei*28;
  }

  .canvascss_right {
    width: 112px;
    height: 112px;
    border-radius: 14px;
    overflow: hidden;
    background: #9FCDFF;
    background-size: 100% 100%;
    padding: 8px;

    img {
      width: 100%;
      border-radius: 6px;
    }
  }

}

/deep/.canvas-box {
  padding-left: 40px !important;
  padding-right: 40px !important;
  box-sizing: border-box;

  .create-img {
    width: 100% !important;
  }
}
</style>
